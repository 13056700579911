import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <body className="index-page">
      <header
        id="header"
        className="header d-flex align-items-center sticky-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <a href="#" className="logo d-flex align-items-center">
            <img src={`${process.env.PUBLIC_URL}/img/logo1.png`} />
          </a>

          <nav
            id="navmenuc:\Program Files\Microsoft VS Code\resources\app\out\vs\code\electron-browser\workbench\workbench.html"
            className="navmenu"
          >
            <ul>
              <li>
                <a href="#hero" className="active">
                  Home
                </a>
              </li>
               
              <li>
                <a href="#services">Services</a>
              </li>
             
              
              
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </header>
      <main className="main">
        <section id="hero" class="hero section light-background">
          <div class="container position-relative">
            <div class="row gy-5">
              <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h2>Business Builder</h2>
                <p>Transform Your Business to a Technology-Driven Enterprise</p>
                <p>
                  In today's fast-paced digital age, transforming your business
                  to be technology-driven is no longer a luxury but a necessity.
                  Embrace innovation and witness a significant increase in
                  profitability and operational efficiency Customer Relationship
                  Management (CRM) software is essential for any business
                  looking to improve their customer interactions and drive
                  sales.
                </p>
                <div class="d-flex">
                  <a href="https://api.whatsapp.com/send?phone=917034040362" class="btn-get-started" target="_blank">
                    Get Started
                  </a>
                  <a
                    // href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                    href="#"
                    class="glightbox btn-watch-video d-flex align-items-center"
                  >
                    <i class="bi bi-play-circle"></i>
                    <span>Watch Video</span>
                  </a>
                </div>
              </div>
              <div class="col-lg-6 order-1 order-lg-2">
                <img src="/img/hero-img.png" class="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div class="icon-boxes position-relative">
            <div class="container position-relative">
              <div class="row gy-4 mt-5">
                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-easel"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                       SALES MANAGEMENT
                      </a>
                    </h4>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-gem"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                        STAFF MANAGEMENT ( HR )
                      </a>
                    </h4>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-geo-alt"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                       PROJECT / TASK MANAGEMENT
                      </a>
                    </h4>
                  </div>
                </div>

                <div class="col-xl-3 col-md-6">
                  <div class="icon-box">
                    <div class="icon">
                      <i class="bi bi-command"></i>
                    </div>
                    <h4 class="title">
                      <a href="" class="stretched-link">
                        REPORTS AND ANALYTICS
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" class="services section">
          <div class="container section-title">
            <h2>Services</h2>
            <div>
              <span>What Problem We</span>{' '}
              <span class="description-title">Solve</span>
            </div>
          </div>

          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-4 col-md-6">
                <div class="service-item  position-relative">
                  <div class="icon">
                    <i class="bi bi-activity"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Missed Follow Up</h3>
                  </a>
                  <p>
                  Automated follow-ups and reminders for pending and overdue construction tasks to avoid delays.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-broadcast"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Lack Of Staff Performance</h3>
                  </a>
                  <p>
                  Monitoring of pending, ongoing, and completed  activities.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-easel"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Low COnversion Ratio</h3>
                  </a>
                  <p>
                  Delayed or inconsistent communication with potential customers
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-bounding-box-circles"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Low Staff Efficiency & Productivity
                    </h3>
                  </a>
                  <p>
                  Teams spend too much time on repetitive tasks, manual data entry, and inefficient workflows, reducing overall productivity and slowing down business growth.
                  </p>
                  <a href="service-details.html" class="stretched-link"></a>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-calendar4-week"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Field Staff Tracking & Supervision Challenges
                    </h3>
                  </a>
                  <p>
                  Business owners struggle to monitor field staff in real time, leading to inefficiencies, missed tasks, and a lack of accountability, which impacts overall productivity and customer satisfaction
                  </p>
                  <a href="service-details.html" class="stretched-link"></a>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="service-item position-relative">
                  <div class="icon">
                    <i class="bi bi-chat-square-text"></i>
                  </div>
                  <a href="service-details.html" class="stretched-link">
                    <h3>Lack of Visibility & Control in Task/Project Management
                    </h3>
                  </a>
                  <p>
                  Project deadlines are missed, tasks are poorly assigned, and progress tracking is inefficient, making it difficult for teams to stay aligned and deliver projects on time.
                  </p>
                  <a href="service-details.html" class="stretched-link"></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" class="features section light-background">
          <div class="container section-title">
            <h2>Features</h2>
            <div>
              <span>Check Our</span>{' '}
              <span class="description-title">Features</span>
            </div>
          </div>

          <div class="container">
            <div class="row gy-5 justify-content-between">
              <div class="col-xl-5">
                <img src="/img/features.png" class="img-fluid" alt="" />
              </div>

              <div class="col-xl-6 d-flex">
                <div class="row align-self-center gy-4">
                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Reminder</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Client Follow System</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Call Performance</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Reporting</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Staff Management</h3>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Location Tracking</h3>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Project Wise P&L</h3>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="feature-box d-flex align-items-center">
                      <i class="bi bi-check"></i>
                      <h3>Resource Utilization</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" class="faq section light-background">
          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-4">
                <div class="content px-xl-5">
                  <h3>
                    <span>Frequently Asked </span>
                    <strong>Questions</strong>
                  </h3>
                  <p>
                  This is an all-in-one business management solution that integrates CRM (Customer Relationship Management), Project Management, and HR Management into a single platform. It helps businesses streamline operations, improve team collaboration, and enhance customer relationships.
                  </p>
                </div>
              </div>

              <div class="col-lg-8">
                <div class="faq-container">
                  <div class="faq-item faq-active">
                    <h3>
                      <span class="num">1.</span>{' '}
                      <span>
                      What is scaleup business builder software used for?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                      This software is an all-in-one solution that combines CRM (Customer Relationship Management), Project Management, and HR Management to streamline business operations, improve efficiency, and enhance collaboration.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">2.</span>{' '}
                      <span>
                      Who can benefit from this software?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                      Businesses of all sizes, including startups, SMEs, and large enterprises, can benefit. It's ideal for sales teams, project managers, HR professionals, and business owners who want to automate and optimize their workflows.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">3.</span>{' '}
                      <span>
                       Is the scaleup Business builder cloud-based or on-premises?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                      Yes, depending on your business needs. The cloud version allows access from anywhere, while the on-premises version offers more control over data security.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">4.</span>{' '}
                      <span>
                      Does this software support mobile access?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                      es, it comes with a mobile-friendly interface and apps for both iOS and Android, allowing users to manage tasks, projects, and customer interactions on the go.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>

                  <div class="faq-item">
                    <h3>
                      <span class="num">5.</span>{' '}
                      <span>
                      What functionalities are included in the project management module?
                      </span>
                    </h3>
                    <div class="faq-content">
                      <p>
                       The project management module includes:

Task & Milestone Tracking – Assign, track, and manage tasks.
Gantt Charts & Kanban Boards – Visualize project progress.
Time Tracking & Timesheets – Monitor work hours and productivity.
Collaboration Tools – Team chat, file sharing, and discussions.
Project Budgeting – Track expenses, resources, and profitability.
                      </p>
                    </div>
                    <i class="faq-toggle bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact section">
          <div class="container section-title">
            <h2>Contact</h2>
            <div>
              <span>Need Help?</span>{' '}
              <span class="description-title">Contact Us</span>
            </div>
          </div>

          <div class="container">
            <div class="row gy-4">
              <div class="col-lg-4">
                <div class="info-item d-flex">
                  <i class="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h3>Address</h3>
                    <p>Room Number 1215 <br/>2 nd Floor<br/> Hilite Business Park ,Calicut</p>
                  </div>
                </div>

                <div class="info-item d-flex">
                  <i class="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Call Us</h3>
                    <p>+91 70340 40362 </p>
                  </div>
                </div>

                <div class="info-item d-flex">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email Us</h3>
                    <p>scaleupbusinessbuilder@gmail.com</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-8">
                <form
                  action="forms/contact.php"
                  method="post"
                  class="php-email-form"
                >
                  <div class="row gy-4">
                    <div class="col-md-6">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        placeholder="Your Name"
                        required=""
                      />
                    </div>

                    <div class="col-md-6 ">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        placeholder="Your Email"
                        required=""
                      />
                    </div>

                    <div class="col-md-12">
                      <input
                        type="text"
                        class="form-control"
                        name="subject"
                        placeholder="Subject"
                        required=""
                      />
                    </div>

                    <div class="col-md-12">
                      <textarea
                        class="form-control"
                        name="message"
                        rows="6"
                        placeholder="Message"
                        required=""
                      ></textarea>
                    </div>

                    <div class="col-md-12 text-center">
                      <div class="loading">Loading</div>
                      <div class="error-message"></div>
                      <div class="sent-message">
                        Your message has been sent. Thank you!
                      </div>

                      <button type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </body>
  );
}

export default App;
